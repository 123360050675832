import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { Box, Stack, Typography } from '@mui/material';

import Iconify from '../../../../components/iconify';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import { AddCash } from '../../../generate-page/components/saving-group/add-cash';
import { CardInfo } from '../../../generate-page/components/saving-group/card-info';
import { CreateGroup } from '../../../generate-page/components/saving-group/create-group';
import { CardSettings } from '../../../generate-page/components/saving-group/card-settings';
import SavingsGroupCard from '../../../generate-page/components/saving-group/saving-group';

type Props = {
  card: any;
  edit: (card: any) => void;
};

const ViewCard = ({ card, edit }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const openShare = useBoolean();
  const settingsModal = useBoolean();
  const addCashModal = useBoolean();
  const openCardInfoModal = useBoolean();
  const isNewCard = !card?.amount && !card?.payOfDate; // Проверяем, является ли это карточка добавления

  const openCardInfo = (id: number) => {
    openCardInfoModal.onTrue();
  };

  return (
    <>
      <Box
        width={isMobile ? '95%' : '95%'}
        height="150px"
        sx={
          card?.images?.length
            ? {
                backgroundImage: `url(${card?.images[0]?.preview})` || '',
                borderRadius: '10px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: isNewCard ? '20px' : '0', // Добавляем padding для новой карточки
              }
            : {
                bgcolor: '#7551DC',
                borderRadius: '10px',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: isNewCard ? '20px' : '0', // Добавляем padding для новой карточки
              }
        }
      >
        {isNewCard ? (
          <Stack alignItems="center">
            <Iconify
              icon="simple-line-icons:plus"
              sx={{ color: 'white', width: '50px', height: '50px' }}
            />
          </Stack>
        ) : (
          <>
            <Stack
              direction="row"
              width={1}
              justifyContent="space-between"
              alignItems="center"
              sx={{ position: 'absolute', top: 0 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openCardInfo(card?.id);
              }}
            >
              <Box p={1} px={2}>
                <Typography sx={{ color: 'white' }}>{card?.title}</Typography>
              </Box>
            </Stack>
            <Grid container spacing={1} mt={1}>
              {/* Остальные элементы карточки */}
              <Grid item xs={8}>
                <Stack p={2}>
                  <Typography sx={{ color: '#9474E8', fontSize: '12px' }}>Loan Amount</Typography>
                  <Stack direction="row" alignItems="center">
                    <Typography sx={{ color: 'white', fontSize: '20px', fontWeight: 1000 }}>
                      $ {card?.amount}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Box sx={{ position: 'relative', width: '60px', height: '60px' }}>
                  <svg width="60" height="60" viewBox="0 0 40 40">
                    <circle cx="20" cy="20" r="15" fill="none" stroke="#d6d6d6" strokeWidth="3" />
                    <circle
                      cx="20"
                      cy="20"
                      r="15"
                      fill="none"
                      stroke="orange"
                      strokeWidth="3"
                      strokeDasharray="94.2"
                      strokeDashoffset={`${94.2 - (parseFloat(card?.percent) / 100) * 94.2}`}
                      strokeLinecap="round"
                      transform="rotate(90 20 20)"
                    />
                  </svg>
                  <Typography
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '10px',
                      color: 'white',
                    }}
                  >
                    {card?.percent}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} p={1}>
                <Stack px={2}>
                  <Typography sx={{ color: '#9474E8', fontSize: '12px', fontWeight: 1000 }}>
                    Pay of date
                  </Typography>
                  <Typography sx={{ fontSize: '14px', color: 'white' }}>
                    {card?.payOfDate}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} p={1}>
                <Stack px={2}>
                  <Typography sx={{ color: '#9474E8', fontSize: '12px', fontWeight: 1000 }}>
                    Loan active
                  </Typography>
                  <Typography sx={{ color: 'white' }}>{card?.loanActive}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {!isNewCard && (
        <Stack direction="row" p={2} justifyContent="center" gap={4}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              edit(card);
            }}
            sx={{ cursor: 'pointer', background: 'black', '&:hover': { background: 'black' } }}
          >
            <Iconify icon="tabler:edit" sx={{ color: 'white' }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openShare.onTrue();
            }}
            sx={{ cursor: 'pointer', background: 'black', '&:hover': { background: 'black' } }}
          >
            <Iconify icon="tabler:share" sx={{ color: 'white' }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addCashModal.onTrue();
            }}
            sx={{ cursor: 'pointer', background: 'black', '&:hover': { background: 'black' } }}
          >
            <Iconify icon="si:money-duotone" sx={{ color: 'white' }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              settingsModal.onTrue();
            }}
            sx={{ cursor: 'pointer', background: 'black', '&:hover': { background: 'black' } }}
          >
            <Iconify icon="mdi:settings-outline" sx={{ color: 'white' }} />
          </IconButton>
        </Stack>
      )}
      <Modal open={openShare.value} onClose={openShare.onFalse}>
        {/* <Share close={openShare.onFalse} /> */}
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '100%' : '50%',
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: isMobile ? '' : '10px',
          }}
        >
          <Stack direction="row" alignItems="center" gap={2} my={1} px={2}>
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid',
                borderColor: '#7551DC',
              }}
            >
              <Iconify
                icon="solar:arrow-left-outline"
                sx={{ color: '#7551DC', cursor: 'pointer' }}
                onClick={openShare.onFalse}
              />
            </Box>
            <Typography>Groups</Typography>
          </Stack>
          <SavingsGroupCard />
        </Box>
      </Modal>
      <Modal open={settingsModal.value} onClose={settingsModal.onFalse}>
        <CardSettings close={settingsModal.onFalse} />
      </Modal>
      <Modal open={addCashModal.value} onClose={addCashModal.onFalse}>
        <AddCash close={addCashModal.onFalse} />
      </Modal>
      <Modal open={openCardInfoModal.value} onClose={openCardInfoModal.onFalse}>
        <CardInfo close={openCardInfoModal.onFalse} card={card} />
      </Modal>
    </>
  );
};

export default ViewCard;
